// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   headline_level -> option / Überschriftengröße
//   subheadline -> text / Unterzeile
//   text -> markdown
// end of automatic

import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import TextReveal from "../reveals/TextReveal"
import BlockWrapper from "../technical/BlockWrapper"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"

const TextBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} showHeadline="false" blockPadding="py-20">
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className="w-full mb-10 lg:w-1/2 px-grid lg:mb-0">
          <HeadlineEnhanced
            kicker={block.kicker}
            headline={block.headline}
            kickerClass={
              block.block_bg?.indexOf("text-white") !== -1 ? "text-white" : ""
            }
          />
        </div>
        <div className="w-full lg:w-1/2 px-grid">
          <div
            className={`hidden lg:block w-48 h-1 mb-12 ${
              block.block_bg?.indexOf("text-white") !== -1
                ? "bg-white"
                : "bg-primary"
            }`}
          ></div>
          <TextReveal>
            <Richtext
              flow={block.flow}
              text={block.text}
              className={block.text_size === "text-lg" ? block.text_size : ""}
            />
          </TextReveal>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default TextBlock
